<template>
  <div class="w-full flex mt-2">
    <div class="flex" v-bind:key="i" v-for="(item, i) in items">
      <router-link class="text-gray-500 no-underline" :to="{ name: item.path }">
        {{item.name}}
      </router-link>
      <chevron-right-icon v-if="i <= (items.length -2)" class="text-gray-500 w-2" />
    </div>
  </div>
</template>
<script>
import ChevronRightIcon from '@/components/icons/chevronRightIcon.vue';

export default {
  name: 'BreadCrumbs',
  components: { ChevronRightIcon },
  props: {
    items: {
      type: Array,
      require: true,
    },
  },
};
</script>
