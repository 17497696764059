<template>
  <div class="w-full flex flex-col">
    <bread-crumbs class="flex justify-end text-xs" :items="routes" />
    <h3 class="text-start mt-4 text-xl">Paqueteria</h3>
    <h4 class="text-start mt-1 mb-4 text-gray-500 text-lg">Crear Paquete</h4>
    <section class="w-full flex flex-col bg-white px-4 py-2">
      <div class="flex grid grid-cols-2">
        <input-field-with-icon
          prepend="#"
          idInput="tracking"
          idFormGroup="tracking-group"
          type="text"
          label="Tracking"
          v-model="newPackage.tracking"
        />
        <select-input
          class="ml-2 w-full"
          idInput="carrier-input"
          idFormGroup="carrier-group"
          label="Transportista"
          v-model="newPackage.carrier"
          :options="carrierOptions"
        />
      </div>
      <div class="flex grid grid-cols-1 my-2">
        <input-field
          isTextArea
          idInput="descriptionInput"
          idFormGroup="description-group"
          type="text"
          label="Descripción"
          v-model="newPackage.description"
        />
      </div>
      <div class="flex grid grid-cols-2 my-2">
        <select-input
          class="mr-1 w-full"
          idInput="client-input"
          idFormGroup="client-group"
          label="Cliente"
          v-model="newPackage.client"
          :options="clientOptions"
        />
        <select-input
          class="ml-1 w-full"
          idInput="store-input"
          idFormGroup="store-group"
          label="Remitente (Tienda)"
          v-model="newPackage.store"
          :options="storeOptions"
        />
      </div>
      <div class="flex grid grid-cols-2 my-2">
        <div class="flex grid grid-cols-3 mr-2">
          <input-field-with-icon
            class="w-full"
            append="cm"
            idInput="large"
            idFormGroup="large-group"
            type="number"
            label="Largo"
            v-model="newPackage.large"
          />
          <input-field-with-icon
            append="cm"
            class="w-4/5 mx-1"
            idInput="width"
            idFormGroup="width-group"
            type="number"
            label="Ancho"
            v-model="newPackage.width"
          />
          <input-field-with-icon
            class="ml-2"
            append="cm"
            idInput="high"
            idFormGroup="high-group"
            type="text"
            label="Alto"
            v-model="newPackage.high"
          />
        </div>
        <div class="flex grid grid-cols-2 ml-2">
          <input-field-with-icon
            append="lbs"
            idInput="volumetricWeight"
            idFormGroup="volumetricWeight-group"
            type="number"
            label="Peso Volumétrico"
            v-model="newPackage.volumetricWeight"
          />
          <input-field-with-icon
            append="lbs"
            class="ml-2"
            idInput="weight"
            idFormGroup="weight-group"
            type="number"
            label="Peso"
            v-model="newPackage.weight"
          />
        </div>
      </div>
      <div class="flex grid grid-cols-2 my-2">
        <select-input
          class="mr-1 w-full"
          idInput="pieces-input"
          idFormGroup="pieces-group"
          label="Piezas"
          v-model="newPackage.pieces"
        />
        <select-input
          class="ml-1 w-full"
          idInput="packaging-input"
          idFormGroup="packaging-group"
          label="Embalaje"
          v-model="newPackage.packaging"
          :options="packagingOptions"
        />
      </div>
      <div class="flex grid grid-cols-1 my-2">
        <radio-buttons
          class="mr-1 w-full"
          idInput="actionRequired-input"
          idFormGroup="actionRequired-group"
          label="Acción requerida"
          :options="actionOptions"
          v-model="newPackage.actionRequired"
        />
      </div>
    </section>
  </div>
</template>
<script>
import InputFieldWithIcon from '@/components/InputFieldWithIcon.vue';
import SelectInput from '@/components/SelectInput.vue';
import InputField from '@/components/InputField.vue';
import RadioButtons from '@/components/RadioButtons.vue';
import BreadCrumbs from '@/components/BreadCrumbs.vue';

export default {
  name: 'PackageEntry',
  components: {
    BreadCrumbs,
    InputFieldWithIcon,
    SelectInput,
    InputField,
    RadioButtons,
  },
  data() {
    return {
      routes: [
        { name: 'Inicio', path: '/' },
      ],
      newPackage: {
        tracking: '',
        carrier: null,
        description: '',
        client: null,
        store: null,
        volumetricWeight: null,
        weight: null,
        actionRequired: false,
      },
      carrierOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Correos de Costa Rica', value: 'gvt' },
        { text: 'UPS', value: 'ups' },
        { text: 'FEDEX', value: 'fedex' },
      ],
      clientOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Alejandro Gomzalez', value: 'alejandro_gonzalez' },
      ],
      storeOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'GV Express', value: 'gv_express' },
      ],
      packagingOptions: [
        { text: 'Seleccione una opcion', value: null },
        { text: 'Box', value: 'Box' },
      ],
      actionOptions: [
        { text: 'No', value: false },
        { text: 'Si', value: true },
      ],
    };
  },
  methods: {
    loadRoutesBreadCrumbs(router) {
      const {
        history: { _startLocation, current, router: { options: { routes } } },
      } = router;
      const startRoute = routes.find((route) => route.path === _startLocation);
      this.routes.push(startRoute);
      if (startRoute.name !== current.name) this.routes.push(current);
    },
  },
  mounted() {
    this.loadRoutesBreadCrumbs(this.$router);
  },
};
</script>
