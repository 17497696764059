<template>
  <div class="w-full">
    <label v-if="label" class="text-sm">{{label}}</label>
    <div class="w-full border border-gray-200 p-2">
      <b-form-select
        class="select"
        :id="idInput"
        :value="value"
        :options="options"
        @input="handleText"
        :required="required"
        :multiple="isMultiple"
        :valueField="valueField"
        :textField="textField"
        @change="$emit('action', value)"
      >
      <template #first>
        <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
      </template>
      </b-form-select>
    </div>
  </div>
</template>
<script>
import {
  BFormSelect,
} from 'bootstrap-vue';

export default {
  name: 'SelectInput',
  components: { BFormSelect },
  props: {
    idInput: {
      type: String,
      require: true,
    },
    idFormGroup: {
      type: String,
      require: true,
    },
    options: {
      type: Array,
      default: () => [],
      require: true,
    },
    label: {
      type: String,
      default: '',
      require: false,
    },
    value: {
      require: true,
    },
    required: {
      type: Boolean,
      default: false,
      require: false,
    },
    isMultiple: {
      type: Boolean,
      default: false,
      require: false,
    },
    valueField: {
      type: String,
      default: 'value',
      require: false,
    },
    textField: {
      type: String,
      default: 'text',
      require: false,
    },
  },
  methods: {
    handleText(value) {
      this.$emit('update:modelValue', value);
      this.$emit('input', value);
    },
  },
};
</script>
<style scoped>
  .select {
    font-family: Arial;
    @apply focus:outline-none text-sm w-full flex p-1 justify-between font-bold;
  }
</style>
