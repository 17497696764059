<template>
  <b-form-group class="text-sm" :label="label" v-slot="{ ariaDescribedby }">
    <b-form-radio-group
      :class="`w-${customWidth} flex justify-between text-sm focus:outline-none`"
      :id="idInput"
      :value="defaultRadioValue"
      v-model="defaultRadioValue"
      :options="options"
      :aria-describedby="ariaDescribedby"
      :name="name"
      @change="handleData"
    ></b-form-radio-group>
  </b-form-group>
</template>
<script>
export default {
  name: 'RadioButtons',
  props: {
    idInput: {
      type: String,
      require: true,
    },
    options: {
      type: Array,
      default: () => [],
      require: true,
    },
    label: {
      type: String,
      default: '',
      require: false,
    },
    value: {
      default: '',
      require: true,
    },
    customWidth: {
      type: String,
      default: '40',
      require: false,
    },
    name: {
      type: String,
      default: '',
      require: true,
    },
  },
  computed: {
    defaultRadioValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('update:modelValue', value);
        this.$emit('input', value);
      },
    },
  },
  methods: {
    handleData(value) {
      this.$emit('update:modelValue', value);
      this.$emit('input', value);
    },
  },
};
</script>
