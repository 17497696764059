<template>
  <b-form-group
    class="text-sm"
    :id="idFormGroup"
    :label="label"
    :label-for="idInput"
    :description="helpText"
    :class="classLabel"
  >
    <b-form-textarea
      class="text-sm focus:outline-none"
      v-if="isTextArea"
      :id="idInput"
      :value="value"
      :type="type"
      :placeholder="placeholder"
      :required="required"
      :rows="rows"
      @input="handleText"
      :max-rows="maxRows"
    ></b-form-textarea>
    <b-form-input
      v-else
      class="text-sm focus:outline-none"
      :id="idInput"
      :value="value"
      :type="type"
      @input="handleText"
      :placeholder="placeholder"
      :required="required"
      :disabled="disabled"
      :class="classInput"
    ></b-form-input>
  </b-form-group>
</template>
<script>
import {
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue';

export default {
  name: 'InputField',
  components: { BFormGroup, BFormInput },
  methods: {
    handleText(value) {
      this.$emit('input', value);
    },
  },
  props: {
    isTextArea: {
      type: Boolean,
      require: false,
      default: false,
    },
    idInput: {
      type: String,
      require: true,
    },
    idFormGroup: {
      type: String,
      require: true,
    },
    rows: {
      ype: Number,
      require: false,
      default: 3,
    },
    maxRows: {
      ype: Number,
      require: false,
      default: 7,
    },
    type: {
      type: String,
      default: 'text',
      require: true,
    },
    label: {
      type: String,
      default: '',
      require: false,
    },
    helpText: {
      type: String,
      default: '',
      require: false,
    },
    value: {
      require: true,
    },
    placeholder: {
      type: String,
      default: '',
      require: false,
    },
    required: {
      type: Boolean,
      default: false,
      require: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      require: false,
    },
    classLabel: {
      type: String,
      default: '',
      require: false,
    },
    classInput: {
      type: String,
      default: '',
      require: false,
    },
  },
};
</script>
